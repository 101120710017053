import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentComponent } from '@modules/registration/components/consent/consent.component';
import { RegistrationInfoComponent } from './registration-info/registration-info.component';

const routes: Routes = [
  { path: '', component: RegistrationInfoComponent },
  { path: 'rules', component: ConsentComponent },
  {
    path: 'registration',
    loadChildren: () => import('@modules/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'business-registration',
    loadChildren: () => import('@modules/business-registration/business-registration.module').then((m) => m.BusinessRegistrationModule),
  },
  {
    path: 'guide',
    loadChildren: () => import('@modules/guide/guide.module').then((m) => m.GuideModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
