import {
  HttpClient,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { ConfigService, initializeConfig } from '@core/services/config.service';
import { ApiModule, Configuration } from '@mol-limo-reg/api';
import { Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CouponComponent } from './coupon/coupon.component';
import { appCheckMarkIcon } from './icons/check-mark';
import { appCloseIcon } from './icons/close';
import { appCouponIcon } from './icons/coupon';
import { appSuccessInModalIcon } from './icons/success-in-modal';
import { RegistrationInfoComponent } from './registration-info/registration-info.component';
import { TimelineComponent } from './timeline/timeline.component';
import { MockInterceptor } from './mock/mock.interceptor';
import { InvitationComponent } from './modules/business-registration/components/invitation/invitation.component';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request);
  }
}

// AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, RegistrationInfoComponent, TimelineComponent, CouponComponent, InvitationComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ApiModule,
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    SvgIconsModule.forRoot({
      icons: [appCloseIcon, appCheckMarkIcon, appCouponIcon, appCloseIcon, appSuccessInModalIcon],
    }),
  ],
  providers: [
    {
      provide: 'googleTagManagerId',
      useFactory: (configService: ConfigService) => configService.get('googleTagManagerId'),
      deps: [ConfigService],
    },
    // {
    //   provide: 'googleTagManagerPreview',
    //   useFactory: (configService: ConfigService) => configService.get('googleTagManagerId'),
    //   deps: [ConfigService],
    // },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeConfig,
      deps: [ConfigService],
    },
    {
      provide: Configuration,
      useFactory: (configService: ConfigService) =>
        new Configuration({
          basePath: configService.get('apiBaseUrl'),
        }),
      deps: [ConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    // enable mocking based on environment configuration
    environment.debug
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: MockInterceptor,
            multi: true,
          },
        ]
      : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
