<h1 class="h1 title">{{ 'TITLE' | translate }}</h1>

<div class="welcome-container">
  <div class="language-container">
    <img alt="question" src="assets/question_illu.png" />
    <select class="select" name="language" (change)="onLanguageChange($event)">
      <option value="hu" selected>HU</option>
      <option value="en">EN</option>
    </select>
  </div>
  <h2 class="h2 subtitle">{{ 'REGISTRATION_INFO.DESCRIPTION' | translate }}</h2>
  <app-timeline [isBusinessRegistration]="isBusinessRegistration"></app-timeline>
  <app-coupon *ngIf="!isBusinessRegistration" [isCoupon]="isCoupon" (openModal)="onOpenModal()"></app-coupon>
</div>

<div class="button-container">
  <button (click)="onNextClicked()" class="btn-primary">{{ 'NEXT' | translate }}</button>
</div>

<div #modal class="modal" [style.display]="displayModal">
  <div class="modal-content">
    <svg-icon key="success-in-modal" class="success-in-modal"></svg-icon>
    <div class="dialog-title">{{ 'REGISTRATION_INFO.COUPON_IS_REDEEMED' | translate }}</div>
    <p class="content">{{ 'REGISTRATION_INFO.COUPON_INFO_2' | translate }}</p>
    <button (click)="onOkayClicked()" class="btn-primary">{{ 'OKAY' | translate }}</button>
  </div>
</div>
