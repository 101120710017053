import { Component, ChangeDetectionStrategy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CouponService } from '@core/services/coupon.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-registration-info',
  templateUrl: './registration-info.component.html',
  styleUrls: ['./registration-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationInfoComponent {
  @ViewChild('modal') public modal: ElementRef;

  public displayModal: 'none' | 'flex' = 'none';
  public isCoupon = false;
  public isBusinessRegistration = false;
  private lang = 'hu';

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private couponService: CouponService,
    private gtmService: GoogleTagManagerService
  ) {
    this.isBusinessRegistration = this.router.getCurrentNavigation()?.extras.state?.isBusinessRegistration;
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(event: Event) {
    if (this.modal.nativeElement === event.target) {
      this.displayModal = 'none';
    }
  }

  public onLanguageChange(event: Event) {
    this.lang = (event.target as any).value;
    this.translate.use(this.lang);
    localStorage.setItem('language', this.lang);
  }

  public onNextClicked() {
    this.gtmService.pushTag({ event: 'consent', type: 'aszf', consent: '1' });
    this.gtmService.pushTag({ event: 'registration', step: 'start', n: '1' });
    this.router.navigate(['rules']);
  }

  public onOkayClicked() {
    this.couponService.setCoupon(this.lang === 'hu' ? 'VANAUTOM' : 'DRIVELIMO');
    this.isCoupon = true;
    this.displayModal = 'none';
  }

  public onOpenModal() {
    this.gtmService.pushTag({ event: 'popup', type: 'coupon', sub_type: 'registration', action: 'visible' });
    this.displayModal = 'flex';
  }
}
