<h1 class="h1 title">{{ 'TITLE' | translate }}</h1>

<div class="consents-container">
  <div class="image-container">
    <img alt="rules" src="assets/rules_illu.png" />
  </div>
  <h2 class="h2 subtitle">{{ 'CONSENTS.TITLE' | translate }}</h2>

  <label class="checkbox-container">
    <strong>{{ 'CONSENTS.ACCEPT_ALL' | translate }}</strong>
    <input type="checkbox" [formControl]="acceptAllControl" (change)="toggleAll()" />
    <span class="checkmark"></span>
  </label>

  <form [formGroup]="rulesForm" class="form">
    <div class="rules-container">
      <label class="checkbox-container">
        <span [innerHTML]="'CONSENTS.TERMS_AND_CONDITIONS' | translate"> </span>
        <input type="checkbox" formControlName="termsAndConditions" (change)="onCheckboxChange()" />
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <span [innerHTML]="'CONSENTS.PRIVACY_POLICY' | translate"></span>
        <input type="checkbox" formControlName="privacyPolicy" (change)="onCheckboxChange()" />
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <span [innerHTML]="'CONSENTS.GENERAL_OFFERS' | translate"></span>
        <input type="checkbox" formControlName="generalOffers" (change)="onCheckboxChange()" />
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <span [innerHTML]="'CONSENTS.PERSONALIZED_OFFERS' | translate"></span>
        <input type="checkbox" formControlName="personalizedOffers" (change)="onCheckboxChange()" />
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <span [innerHTML]="'CONSENTS.PROMOTIONS' | translate"></span>
        <input type="checkbox" formControlName="promotions" (change)="onCheckboxChange()" />
        <span class="checkmark"></span>
      </label>
    </div>
  </form>
</div>

<div class="button-container">
  <button [disabled]="rulesForm.invalid" (click)="onNextClicked()" class="btn-primary">{{ 'NEXT' | translate }}</button>
</div>
