import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponComponent {
  @Input() public isCoupon = false;
  @Output() public openModal = new EventEmitter<void>();

  constructor() {}

  public onRedeemCouponClicked() {
    this.openModal.emit();
  }
}
