<div class="timeline-item">
  <div class="circle"></div>
  <div class="font-weight-bold">{{ 'REGISTRATION_INFO.FIRST_STEP_TITLE' | translate }}</div>
  <p>{{ 'REGISTRATION_INFO.FIRST_STEP_INFO' | translate }}</p>
</div>
<div class="timeline-item">
  <div class="circle">
    <div class="inner-circle"></div>
  </div>
  <div class="font-weight-bold">{{ 'REGISTRATION_INFO.SECOND_STEP_TITLE' | translate }}</div>
</div>
<div *ngIf="!isBusinessRegistration" class="timeline-item">
  <div class="circle">
    <div class="inner-circle"></div>
  </div>
  <div class="font-weight-bold">{{ 'REGISTRATION_INFO.THIRD_STEP_TITLE' | translate }}</div>
</div>
<div class="timeline-item">
  <div class="circle">
    <div class="inner-circle">
      <svg-icon class="check-mark" key="check-mark"></svg-icon>
    </div>
  </div>
  <div class="font-weight-bold">{{ 'REGISTRATION_INFO.FOURTH_STEP_TITLE' | translate }}</div>
</div>
