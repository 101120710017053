import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public title = 'mol-limo-registration';

  constructor(translate: TranslateService, gtmService: GoogleTagManagerService) {
    gtmService.addGtmToDom();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('hu');
    localStorage.setItem('language', 'hu');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('hu');
  }
}
