<svg-icon class="coupon" key="coupon"></svg-icon>
<div class="redeem-coupon-container">
  <p class="redeem-coupon-info">{{ 'REGISTRATION_INFO.COUPON_INFO' | translate }}</p>

  <button *ngIf="!isCoupon; else coupon" (click)="onRedeemCouponClicked()" class="redeem-coupon-btn">
    {{ 'REGISTRATION_INFO.COUPON_BUTTON' | translate }}
  </button>
  <ng-template #coupon>
    <div class="redeemed">
      <div class="h2">{{ 'REGISTRATION_INFO.COUPON_IS_REDEEMED' | translate }}</div>
      <svg-icon style="width: 32px; height: 32px" key="success-in-modal" class="success-in-modal"></svg-icon>
    </div>
  </ng-template>
</div>
