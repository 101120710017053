import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';
import { Config } from '../types/config.interface';

export const initializeConfig = (configService: ConfigService) => () => configService.initialize();

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly loaded$ = new BehaviorSubject(false);
  private config!: Config;
  private httpClient: HttpClient;

  public constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public async initialize() {
    try {
      const config = await this.httpClient.get<Config>('/assets/config/config.json').toPromise();
      this.loaded$.next(true);
      this.config = config;
    } catch (error) {
      alert('Could not load application config.');

      throw error;
    }
  }

  public onLoad$(): Observable<void> {
    return this.loaded$.pipe(
      first(() => true),
      mapTo(undefined)
    );
  }

  public get<T extends keyof Config>(key: T): Config[T] {
    return this.config[key];
  }
}
