import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  private coupon$ = new BehaviorSubject<string>('');

  constructor() {}

  public getCoupon$(): Observable<string> {
    return this.coupon$.asObservable();
  }

  public setCoupon(url: string) {
    this.coupon$.next(url);
  }
}
