import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentComponent {
  public rulesForm = new UntypedFormGroup({
    termsAndConditions: new UntypedFormControl(false, Validators.requiredTrue),
    privacyPolicy: new UntypedFormControl(false, Validators.requiredTrue),
    generalOffers: new UntypedFormControl(false),
    personalizedOffers: new UntypedFormControl(false),
    promotions: new UntypedFormControl(false),
  });

  public acceptAllControl = new UntypedFormControl(false);

  constructor(private readonly router: Router) {}

  public toggleAll() {
    //if at least one of the checkboxes is not checked, check all
    if (Object.values(this.rulesForm.controls).some((control) => !control.value)) {
      this.rulesForm.setValue({
        termsAndConditions: true,
        privacyPolicy: true,
        generalOffers: true,
        personalizedOffers: true,
        promotions: true,
      });
    } else {
      this.rulesForm.setValue({
        termsAndConditions: false,
        privacyPolicy: false,
        generalOffers: false,
        personalizedOffers: false,
        promotions: false,
      });
    }
  }

  public onNextClicked() {
    this.router.navigate(['registration'], {
      state: {
        directMarketingAccepted: this.rulesForm.value.generalOffers,
        profilingConsentAccepted: this.rulesForm.value.personalizedOffers,
        dataSharingAccepted: this.rulesForm.value.promotions,
      },
    });
  }

  // it toggles the acceptAllControl checkbox based on the state of the other checkboxes
  public onCheckboxChange() {
    this.acceptAllControl.setValue(Object.values(this.rulesForm.controls).every((control) => control.value));
  }
}
