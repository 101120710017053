import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { RegisterUserRequest } from '@mol-limo-reg/api';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { PaymentDataMockService } from './services/payment-data-mock.service';

import { RegistrationDataMockService } from './services/registration-data-mock.service';
import { UserManagementDataMockService } from './services/usermanagement-data-mock.service';
import { VerificationDataMockService } from './services/verification-data-mock.service';

const MOCK_RESPONSE_DELAY_MS = 1500;
const NOT_MOCKED_RESPONSE = new HttpResponse({
  status: 500,
  body: '[NOT MOCKED] not mocked',
});

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  private readonly registrationDataMockService: RegistrationDataMockService;
  private readonly userManagementDataMockService: UserManagementDataMockService;
  private readonly verificationDataMockService: VerificationDataMockService;
  private readonly paymentDataMockService: PaymentDataMockService;

  private readonly store: Map<string, RegisterUserRequest>;

  constructor(private readonly configService: ConfigService) {
    this.store = new Map<string, RegisterUserRequest>();
    this.registrationDataMockService = new RegistrationDataMockService(this.store);
    this.userManagementDataMockService = new UserManagementDataMockService(this.store);
    this.verificationDataMockService = new VerificationDataMockService();
    this.paymentDataMockService = new PaymentDataMockService();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.configService.get('apiMocking')) {
      return next.handle(request);
    }

    if (!request.url.startsWith(this.configService.get('apiBaseUrl'))) {
      return next.handle(request);
    }
    // eslint-disable-next-line no-console
    console.log(`[MOCK] intercepting request ${request.method} ${request.urlWithParams} | body: ${JSON.stringify(request.body)}`);

    const method = request.method;
    const found = request.url.match(/^.+?[^/:](?=[/?]|$)/);
    const baseUrl = found ? found[0] : '';
    if (baseUrl.includes('/assets')) {
      return next.handle(request);
    }
    const path = request.url.slice(baseUrl.length);
    let response: HttpResponse<any>;

    // default leave it as NOT_MOCKED
    response = NOT_MOCKED_RESPONSE;

    // user management data
    if (method === 'POST' && path === '/user/register') {
      response = this.userManagementDataMockService.registerUser(request);
    } else if (method === 'POST' && path === '/user/personal-details') {
      response = this.userManagementDataMockService.saveUserPersonalData(request);
    } else if (method === 'POST' && path === '/user/addressinfo') {
      response = this.userManagementDataMockService.saveAddressInfo(request);
    } else if (method === 'POST' && path === '/user/drivinglicense') {
      response = this.userManagementDataMockService.uploadLicense(request);
    } else if (method === 'POST' && path === '/user/terms') {
      response = this.userManagementDataMockService.acceptTerms(request);
    } else if (method === 'POST' && path === '/user/selectplan') {
      response = this.userManagementDataMockService.selectPlan(request);
    }
    // registration data
    else if (method === 'POST' && path === '/registration/getstate') {
      response = this.registrationDataMockService.getRegistrationState(request);
    } else if (method === 'POST' && path === '/registration/continue') {
      response = this.registrationDataMockService.continueRegistration(request);
    }
    // verification data
    else if (method === 'POST' && path === '/phone/sendcode') {
      response = this.verificationDataMockService.getCode(request);
    } else if (method === 'POST' && path === '/phone/verify') {
      response = this.verificationDataMockService.checkCode(request);
    }
    // verification data
    else if (method === 'GET' && path === '/payment/methods') {
      response = this.paymentDataMockService.getPaymentMethods();
    } else if (method === 'POST' && path === '/payment') {
      response = this.paymentDataMockService.sendPaymentRequest(request);
    } else if (method === 'POST' && path === '/payment/details') {
      response = this.paymentDataMockService.sendPaymentDetailsRequest(request);
    }

    // not implemented
    else {
      // eslint-disable-next-line no-console
      console.error(`[MOCK] request ${request.method} ${request.url} cannot be fulfilled`);
      response = new HttpResponse({ status: 500 });
    }

    // eslint-disable-next-line no-console
    console.log('[MOCK] sending response');
    // eslint-disable-next-line no-console
    console.log(response.body);

    return of(response).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 200) {
          throw new HttpErrorResponse({ status: event.status });
        }
        return event;
      }),
      delay(MOCK_RESPONSE_DELAY_MS)
    );
  }
}
